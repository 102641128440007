import { classToPlain, plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { apiInstance, useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  CarrierBrokerPreferences,
  carrierBrokerPreferencesSchema,
  CarrierFullInfo,
} from 'shared/types/carrier';
import {
  carrierRatingSchema,
  carrierRatingTagStatsSchema,
  lastRatedAtSchema,
  RateCarrierPayloadDTO,
  unratedCarriersCountSchema,
} from './CarrierRatingDTO';

function useCarriersCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateCarriers() {
      void queryClient.invalidateQueries('carriers');
    }

    return { invalidateCarriers };
  }, [queryClient]);
}

export function useCarrierRatingAPI() {
  const cache = useCarriersCache();
  const { requestResource, request } = useAPI();

  return useMemo(
    () => ({
      rateCarrier: (guid: string, payload: RateCarrierPayloadDTO) =>
        requestResource<CarrierBrokerPreferences>(
          `POST /internal/carriers/${guid}/ratings`,
          (data) => carrierBrokerPreferencesSchema.cast(data),
          { json: classToPlain(payload) },
        ).then((response) => {
          cache.invalidateCarriers();
          return response;
        }),

      deleteRating: (guid: string) =>
        request(`DELETE /internal/carriers/${guid}/ratings`).then(
          (response) => {
            cache.invalidateCarriers();
            return response;
          },
        ),
    }),
    [requestResource, cache, request],
  );
}

export type RatingSortOption =
  | 'newest'
  | 'negative-ratings'
  | 'positive-ratings';

export function useCarrierRatings(
  guid: string,
  page: number,
  sort: RatingSortOption,
) {
  return useAPIPageQuery(['carriers', 'ratings', { guid, page, sort }], () =>
    apiInstance.requestPage(
      '/internal/carriers/{guid}/ratings{?size,page,sort*}',
      (data) => carrierRatingSchema.cast(data),
      {
        guid,
        page,
        size: 10,
        sort:
          sort === 'newest'
            ? ['ratingCreatedAt,desc']
            : sort === 'negative-ratings'
            ? ['rating,asc', 'ratingCreatedAt,desc']
            : ['rating,desc', 'ratingCreatedAt,desc'],
      },
    ),
  );
}

export function useCarrier(guid: string) {
  const { requestResource } = useAPI();

  return useAPIQuery(['carriers', 'item', { guid }], () =>
    requestResource(
      'GET /internal/carriers/{guid}',
      (data) => plainToClass(CarrierFullInfo, data),
      { guid },
    ),
  );
}

export function useCarrierLastRatedAt(guid: string | undefined) {
  const { requestResource } = useAPI();
  return useAPIQuery(
    ['carriers', 'last_rated_at', { guid }],
    () =>
      requestResource(
        'GET /internal/carriers/{guid}/last_rated_at',
        (data) => lastRatedAtSchema.cast(data),
        { guid },
      ),
    { enabled: !!guid },
  );
}

export function useCarrierRatingTagStats(guid: string) {
  const { requestResource } = useAPI();
  return useAPIQuery(['carriers', 'ratings', { guid }], () =>
    requestResource(
      'GET /internal/carriers/{guid}/ratings/statistics',
      (data) => carrierRatingTagStatsSchema.cast(data),
      { guid },
    ),
  );
}

export function useUnratedCarriersCount() {
  const { requestResource } = useAPI();
  return useAPIQuery(['ratings', 'unrated-carriers-count'], () =>
    requestResource('GET /internal/carriers/unrated_count', (data) =>
      unratedCarriersCountSchema.cast(data),
    ),
  );
}
